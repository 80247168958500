import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Layout, PageHeader, Seo } from '../components';
import { BsArrowRightSquareFill } from 'react-icons/bs';

const Carrier = ({ data }) => {
   return (
      <Layout>
         <Seo title='Carriers' />
         <PageHeader
            title='Carriers'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />

         <CarrierRegistration className='container'>
            <h2>Carrier Registration</h2>
            <p>
               Thank you for your interest in becoming a quality carrier for
               Barcode Buildup.
            </p>
            <p>
               As one of the reliable freight brokerage firms that works hard to
               secure the highest paying rates, we look forward to booking all
               your future loads. This process will take approximately 10
               minutes to complete. If you have any questions about Barcode
               Buildup or the Carrier Contract, please contact us at
               561.926.3080.
            </p>
            <div className='recommend'>
               <h3>To expedite the process, we recommend that you have:</h3>
               <ul>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     Authorization to sign a binding contract for your company.
                  </li>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     Your insurance broker's contact information OR a copy of
                     the Certification of Insurance of both CARGO and LIABILITY
                     insurance from your broker.
                  </li>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     Company TAX ID information.
                  </li>
               </ul>
            </div>
            <div className='disclaimer'>
               <h4>Legal Disclaimer Or Requirements:</h4>
               <p>
                  By proceeding with this registration and Carrier Contract, you
                  acknowledge that
               </p>
               <ul>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     You are a representative of the carrier
                  </li>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     You have the authority to enter into contracts binding the
                     carrier,
                  </li>
                  <li>
                     <BsArrowRightSquareFill className='icon' />
                     Your acts in submitting the Carrier Contract electronically
                     are intended to be your electronic signature on the Carrier
                     Contract and registration.
                  </li>
               </ul>
            </div>

            {/* <a
               href='https://docs.google.com/forms/d/e/1FAIpQLSeSQHyXC_nK06VK3V_d_DgLTZb2xvc3bz-U-qhp7dAkjaKaUA/viewform'
               target='_blank'
               rel='noreferrer'
            >
               <button className='dark-btn'>Go to next Step</button>
            </a> */}
            <Link to='/contact'>
               <button className='dark-btn'>Go to next Step</button>
            </Link>
         </CarrierRegistration>
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "carriers-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
   }
`;

export default Carrier;

const CarrierRegistration = styled.section`
   max-width: 800px !important;

   li {
      margin: 0 0 1rem;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
      gap: 0.75rem;
      line-height: 25px;

      .icon {
         font-size: 0.9rem;
         margin-top: 7px;
      }
   }

   .recommend,
   .disclaimer {
      padding: 1rem 0;
   }
`;
